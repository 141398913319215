import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import './Privacy_Policy.css';
// import { Footer2 } from './footer2';

const PrivacyPage = ({ setHello }) => {
    // const navigate = useNavigate();

    useEffect(() => {
        setHello(true);
    }, [setHello]);

    return (
        
        <div className='main_div'>
            <div className="col-md-12">
                <h2 className="heading-large" style={{ marginTop: '60px', marginBottom: '20px' }}>PRIVACY POLICY</h2>
                <h4> Last updated: June 17, 2024.</h4>
                <p className="paragraph-large" style={{ marginTop: '20px' }}>
                    This privacy notice for TechShy Private Limited ('we', 'us', or 'our'),
                    describes how and why we might collect, store, use, and/or share ('process')
                    your information when you use our services ('Services'), such as when you:
                    <br/> <br/>
                    • visit this website at <a href="www.laathi.com"> www.laathi.com </a>
                    or any website of ours that links to this privacy notice; and <br/>
                    • install and use the mobile application.
                    <br/> <br/>
                    Questions or concerns? Reading this privacy notice will help you understand
                    your privacy rights and choices. If you do not agree with our policies and
                    practices, please do not use our services. If you still have any questions
                    or concerns, please contact us at
                    <a href="mailto:help@laathi.com"> help@laathi.com</a>.
                    <br/> <br/>
                </p>
                <h2 className="heading-large2" style={{ marginBottom: '10px' }}>
                SUMMARY OF KEY POINTS</h2>
                <p className="paragraph-large">
                    This summary provides key points from our privacy notice, but you can find
                    out more details about all these topics by clicking the link following
                    each key point or by using our table of contents below to find the section
                    you are looking for.<br/> <br />

                    What personal information do we process? When you visit, use, or
                    navigate our Services, we may process personal information depending on
                    how you interact with us and the Services, the choices you make, and the
                    products and features you use. Please see below to learn more about the
                    personal information you disclose to us.<br/> <br/>

                    Do we process any sensitive personal information? We do not process
                    sensitive personal information.<br/> <br/>

                    Do we receive any information from third parties? We do not receive any
                    information from third parties.<br/> <br/>

                    How do we process your information? We process your information to provide,
                    improve, and administer our Services, communicate with you, for security
                    and fraud prevention, and to comply with law. We may also process your
                    information for other purposes with your consent. We process your
                    information only when we have a valid legal reason to do so. Please see
                    below to learn more about how we process your information.<br/> <br/>

                    In what situations and with which parties do we share personal information?
                    We may share information in specific situations and with specific
                    third parties. Please see below to learn more about when and with whom
                    we share your personal information.<br/> <br/>

                    How do we keep your information safe? We have organisational and technical
                    processes and procedures in place to protect your personal information.
                    However, no electronic transmission over the internet or information
                    storage technology can be guaranteed to be 100% secure, so we cannot
                    promise or guarantee that hackers, cybercriminals, or other unauthorised
                    third parties will not be able to defeat our security and improperly
                    collect, access, steal, or modify your information. Please see below to
                    learn more about how we keep your information safe.<br/> <br/>

                    What are your rights? Depending on where you are located geographically,
                    the applicable privacy law may mean you have certain rights regarding your
                    personal information. Please see below to learn more about your
                    privacy rights. <br/> <br/>


                    Want to learn more about what we do with any information we collect?
                    Please see below to review the privacy notice in full .<br/> <br/>
                </p>

                <h2 className="heading-large2" style={{ marginBottom: '10px' }}>
                TABLE OF CONTENTS</h2>
                <p className="paragraph-large">
                <a href="#1" style={{ color: 'blue', textDecoration: 'underline' }}>
                1. WHAT INFORMATION DO WE COLLECT?</a><br />
                <a href="#2" style={{ color: 'blue', textDecoration: 'underline' }}>
                2. HOW DO WE PROCESS YOUR INFORMATION?</a><br />
                <a href="#3" style={{ color: 'blue', textDecoration: 'underline' }}>
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a><br />
                <a href="#4" style={{ color: 'blue', textDecoration: 'underline' }}>
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a><br />
                <a href="#5" style={{ color: 'blue', textDecoration: 'underline' }}>
                5. HOW LONG DO WE KEEP YOUR INFORMATION?</a><br />
                <a href="#6" style={{ color: 'blue', textDecoration: 'underline' }}>
                6. HOW DO WE KEEP YOUR INFORMATION SAFE?</a><br />
                <a href="#7" style={{ color: 'blue', textDecoration: 'underline' }}>
                7. DO WE COLLECT INFORMATION FROM MINORS?</a><br />
                <a href="#8" style={{ color: 'blue', textDecoration: 'underline' }}>
                8. WHAT ARE YOUR PRIVACY RIGHTS?</a><br />
                <a href="#9" style={{ color: 'blue', textDecoration: 'underline' }}>
                9. CONTROLS FOR DO-NOT-TRACK FEATURES?</a><br />
                <a href="#10" style={{ color: 'blue', textDecoration: 'underline' }}>
                10. DO WE MAKE UPDATES TO THIS NOTICE?</a><br />
                <a href="#11" style={{ color: 'blue', textDecoration: 'underline' }}>
                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a><br />
                
                </p>
                <h2 id="1" className="heading-large2" style={{ marginBottom: '10px' }}>
                1. WHAT INFORMATION DO WE COLLECT?</h2>
                <p className="paragraph-large">
                    <i><b>In Short: </b>
                    We collect personal information that you provide to us. </i><br/> <br/>

                    <b>Personal information you disclose to us. </b>
                    We collect personal information that you
                    voluntarily provide to us when you register on the Services,  express an
                    interest in obtaining information about us or our products and Services,
                    when you participate in activities on the Services, or otherwise when you
                    contact us.<br/> <br/>

                    <b>Personal Information Provided by You. </b>
                    The personal information that we collect depends on the context of your
                    interactions with us and the Services, the choices you make, and the
                    products and features you use. The personal information we collect may
                    include the following:<br/> <br/>

                    • Names<br/> <br/>
                    • Phone numbers<br/> <br/>
                    • Email addresses<br/> <br/>
                    • Usernames<br/> <br/>
                    • Passwords<br/> <br/>
                    • Billing addresses<br/> <br/>
                    • Contact or authentication data<br/> <br/>

                    <b>Sensitive Information. </b>
                    We do not process sensitive information.<br/> <br/>

                    All personal information that you provide to us must be true, complete,
                    and accurate, and you must notify us of any changes to such personal
                    information.<br/> <br/>
                </p>

                <h2 id="2" className="heading-large2" style={{ marginBottom: '10px' }}>
                2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                <p className="paragraph-large">
                    <i> <b>In Short:</b>
                    We process your information to provide, improve, and administer
                    our Services, communicate with you, for security and fraud prevention,
                    and to comply with law. We may also process your information for other
                    purposes with your consent. </i> <br/> <br/>

                    <b>We process your personal information for a variety of reasons, depending
                    on how you interact with our Services, including:</b> <br/> <br/>

                    <b>• To fulfil and manage your orders.</b> We may process your information to
                    fulfil and manage your orders, payments, returns, and exchanges made through
                    the Services.<br/> <br/>

                    <b>• To enable user-to-user communications.</b> We may process your
                    information if you choose to use any of our offerings that allow for
                    communication with another user.<br/> <br/>

                    <b>• To request feedback.</b> We may process your information when
                    necessary to request feedback and to contact you about your use of
                    our Services.<br/> <br/>

                    <b>• To deliver targeted advertising to you.</b> We may process your
                    information to develop and display personalised content and advertising
                    tailored to your interests, location, and more.<br/> <br/>

                    <b>• To comply with our legal obligations.</b> We may process your
                    information to comply with our legal obligations, respond to legal
                    requests, and exercise, establish, or defend our legal rights.<br/> <br/>

                </p>

                <h2 id="3" className="heading-large2" style={{ marginBottom: '10px' }}>
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    <i><b>In Short: </b>
                    We may share information in specific situations described in this
                    section and/or with the following third parties.</i><br/> <br/>

                    We may need to share your personal information in the following
                    situations:<br/> <br/>

                    <b>Business Transfers. </b>
                    We may share or transfer your information in connection with, or during
                    negotiations of, any merger, sale of company assets, financing, or
                    acquisition of all or a portion of our business to another company.
                    <br/> <br/>

                    <b>When we use Google Maps Platform APIs.</b> We may share your
                    information with certain Google Maps Platform APIs (e.g. Google Maps
                    API, Places API).<br/> <br/>
                </p>

                <h2 id="4" className="heading-large2" style={{ marginBottom: '10px' }}>
                4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    <i><b>In Short:</b> We may use cookies and other tracking technologies to
                    collect and store your information.</i><br/> <br/>

                    We may need to share your personal information in the following
                    situations:<br/> <br/>

                    We may use cookies and similar tracking technologies (like web beacons
                    and pixels) to access or store information. Specific information about
                    how we use such technologies and how you can refuse certain cookies is
                    set out in our Cookie Notice .<br/> <br/>
                </p>

                <h2 id="5" className="heading-large2" style={{ marginBottom: '10px' }}>
                5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    <i><b>In Short:</b> We keep your information for as long as necessary
                    to fulfil the purposes outlined in this privacy notice unless otherwise
                    required by law.</i><br/> <br/>

                    We will only keep your personal information for as long as it is necessary
                    for the purposes set out in this privacy notice, unless a longer retention
                    period is required or permitted by law (such as tax, accounting, or other
                    legal requirements).<br/> <br/>

                    When we have no ongoing legitimate business need to process your personal
                    information, we will either delete or anonymise such information, or, if
                    this is not possible (for example, because your personal information has
                    been stored in backup archives), then we will securely store your personal
                    information and isolate it from any further processing until deletion is
                    possible.<br/> <br/>
                </p>

                <h2 id="6" className="heading-large2" style={{ marginBottom: '10px' }}>
                6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    <i><b>In Short:</b> We aim to protect your personal information through a
                    system of organisational and technical security measures.</i><br/> <br/>

                    We have implemented appropriate and reasonable technical and organisational
                    security measures designed to protect the security of any personal
                    information we process. However, despite our safeguards and efforts to
                    secure your information, no electronic transmission over the Internet or
                    information storage technology can be guaranteed to be 100% secure, so
                    we cannot promise or guarantee that hackers, cybercriminals, or other
                    unauthorised third parties will not be able to defeat our security and
                    improperly collect, access, steal, or modify your information. Although
                    we will do our best to protect your personal information, transmission of
                    personal information to and from our Services is at your own risk. You
                    should only access the Services within a secure environment.<br/> <br/>
                </p>

                <h2 id="7" className="heading-large2" style={{ marginBottom: '10px' }}>
                7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    <i><b>In Short:</b> We do not knowingly collect data from or market to
                    children under 18 years of age. </i><br/> <br/>

                    We do not knowingly solicit data from or market to children under
                    18 years of age. By using the Services, you represent that you are at
                    least 18 or that you are the parent or guardian of such a minor and
                    consent to such minor dependent's use of the Services. If we learn
                    that personal information from users less than 18 years of age has
                    been collected, we will deactivate the account and take reasonable
                    measures to promptly delete such data from our records. If you become
                    aware of any data we may have collected from children under age 18,
                    please contact us at <a href="mailto:help@laathi.com"> help@laathi.com</a>.
                    <br/> <br/>
                </p>

                <h2 id="8" className="heading-large2" style={{ marginBottom: '10px' }}>
                8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    <i><b>In Short:</b> You may review, change, or terminate your account
                    at any time.</i><br/> <br/>

                    <b><u>Withdrawing your consent:</u></b> If we are relying on your consent
                    to process your personal information, which may be express and/or implied
                    consent depending on the applicable law, you have the right to withdraw
                    your consent at any time. You can withdraw your consent at any time by
                    contacting us at <a href="mailto:help@laathi.com"> help@laathi.com</a>.
                    <br/> <br/>

                    However, please note that this will not affect the lawfulness of the
                    processing before its withdrawal nor, when applicable law allows, will
                    it affect the processing of your personal information conducted in reliance
                    on lawful processing grounds other than consent.<br/> <br/>

                    <b>Account Information</b><br/> <br/>

                    If you would at any time like to review or change the information in
                    your account or terminate your account, you can:<br/> <br/>
                    Upon your request to terminate your account, we will deactivate or
                    delete your account and information from our active databases. However,
                    we may retain some information in our files to prevent fraud, troubleshoot
                    problems, assist with any investigations, enforce our legal terms and/or
                    comply with applicable legal requirements.<br/> <br/>

                    If you have questions or comments about your privacy rights, you may
                    email us at <a href="mailto:help@laathi.com"> help@laathi.com</a>.
                    <br/> <br/>
                </p>

                <h2 id="9" className="heading-large2" style={{ marginBottom: '10px' }}>
                9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    Most web browsers and some mobile operating systems and mobile applications
                    include a Do-Not-Track ( 'DNT' ) feature or setting you can activate to
                    signal your privacy preference not to have data about your online browsing
                    activities monitored and collected. At this stage no uniform technology
                    standard for recognising and implementing DNT signals has been finalised.
                    As such, we do not currently respond to DNT browser signals or any other
                    mechanism that automatically communicates your choice not to be tracked
                    online. If a standard for online tracking is adopted that we must follow
                    in the future, we will inform you about that practice in a revised version
                    of this privacy notice.<br/> <br/>
                </p>

                <h2 id="10" className="heading-large2" style={{ marginBottom: '10px' }}>
                10. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    <i><b>In Short:</b> Yes, we will update this notice as necessary to stay
                    compliant with relevant laws.</i><br/> <br/>

                    We may update this privacy notice from time to time. The updated version
                    will be indicated by an updated 'Revised' date and the updated version
                    will be effective as soon as it is accessible. If we make material changes
                    to this privacy notice, we may notify you either by prominently posting
                    a notice of such changes or by directly sending you a notification.
                    We encourage you to review this privacy notice frequently to be informed
                    of how we are protecting your information.<br/> <br/>
                </p>

                <h2 id="11" className="heading-large2" style={{ marginBottom: '10px' }}>
                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    If you have questions or comments about this notice, you may contact us at:
                    <br/>
                </p>
                <p className="paragraph-large" style={{ marginBottom: '40px' }}>
                TechShy Private Limited<br />
                <a href="/pages/contact.jsx" className="underline-on-hover">
                        Contact Us
                    </a>
                    <br />
                    <a href="mailto:help@laathi.com" className="underline-on-hover">
                        help@laathi.com
                    </a>
                </p>

            </div>
        </div>
        
    );
};

export default PrivacyPage;
