import React, { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import emailjs from "emailjs-com";
// import ReactDOM from 'react-dom';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import './contact.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';


const firebaseConfig = {
    // Your Firebase configuration
    apiKey: "AIzaSyAAMZ4SiaLtVRBqMaXehzDwWYZ55dAOfNE",
    authDomain: "laathi-webpage.firebaseapp.com",
    projectId: "laathi-webpage",
    storageBucket: "laathi-webpage.appspot.com",
    messagingSenderId: "679746648873",
    appId: "1:679746648873:web:9cf21b2e7b533eb004d538",
    measurementId: "G-QNNG4QGVQW"
};

firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();

const initialState = {
    name: "",
    phone: "",
    email: "",
    message: "",
};

export const Contact1 = (props) => {
    const [{ name, phone, email, message }, setState] = useState(initialState);
    const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const clearState = () => {
        setState({ ...initialState });
        setIsSubmitted(true); // Set form submission flag
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(name, phone, email, message);

        try {
            await firestore.collection("contact").add({
                name,
                phone,
                email,
                message,
                timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            });
            console.log("Form data saved to Firestore");
            clearState();
        } catch (error) {
            console.error("Error saving form data to Firestore:", error);
        }

        emailjs
            .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_PUBLIC_KEY")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };

    return (
        <div>

            <div id="contact1">
                <div className="container">

                    <div className="col-md-8">
                        {props.setHello(true)}
                        {isSubmitted ? (
                            <div style={{ height: "60rem", marginTop: "30" }}>
                                <div className="container" style={{ color: "white", paddingTop: "10rem" }}>
                                    <h3 style={{ color: "white", fontSize: "38", marginLeft: "35" }}>Thank you for contacting us!</h3>
                                    {/* <h3 style={{ color: "white", fontSize: "18" }}>We will get back to you as soon as possible.</h3> */}
                                    <p style={{ fontSize: "16px", marginBottom: "50", marginLeft: "120", marginTop: "1px" }}>We will get back to you as soon as possible.</p>
                                    <div id="success"></div>
                                    <a href="/pages/contact.jsx" className="btn btn-custom btn-lg" style={{ marginLeft: "135px" }}>
                                        Send Another Message
                                    </a>


                                </div>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="section-title">
                                    <h2>GET IN TOUCH</h2>
                                    <p>
                                        Please fill out the form below to send us an email and we will
                                        get back to you as soon as possible.
                                    </p>
                                </div>
                                <form name="sentMessage" validate onSubmit={handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    className="form-control"
                                                    placeholder="Name"
                                                    required
                                                    onChange={handleChange}
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <input
                                                    type="tel"
                                                    id="phone"
                                                    name="phone"
                                                    className="form-control"
                                                    placeholder="Contact No"
                                                    required
                                                    onChange={handleChange}
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            className="form-control"
                                            placeholder="Email"
                                            required
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <textarea
                                            name="message"
                                            id="message"
                                            className="form-control"
                                            rows="4"
                                            placeholder="Message"
                                            required
                                            onChange={handleChange}
                                        ></textarea>
                                        <p className="help-block text-danger"></p>
                                    </div>
                                    <div id="success"></div>
                                    <button type="submit" className="btn btn-custom btn-lg">
                                        Send Message
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>

                    <div className="col-md-3 col-md-offset-1 contact-info" style={{ marginTop: "80" }}>
                        <div className="contact-item">
                            <h3>Contact Info</h3>
                            <p>
                                <span>
                                    <i className="fa fa-map-marker"></i> Address
                                </span>
                                {props.data ? props.data.address : "loading"}
                                {console.log(props.data)}

                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-phone"></i> Phone
                                </span>{" "}
                                {props.data ? props.data.phone : "loading"}
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-envelope-o"></i> Email
                                </span>{" "}
                                {props.data ? props.data.email : "loading"}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="social">
                                <div className="footerNav">
                                    <ul>
                                        <li><a href="/#Home" className="page-scroll">Home</a></li>
                                        {/* <li><a href="/pages/team2.jsx" className="page-scroll">Our Team</a></li> */}
                                        <li><a href="/pages/contact.jsx" className="page-scroll">Contact Us</a></li>
                                        <li><a href="/pages/privacy_policy.jsx">Privacy Policy</a></li>
                                        <li><a href="/pages/terms_&_condition.jsx">Terms & Conditions</a></li>
                                    </ul>
                                </div>
                                <ul>
                                    <li>
                                        <a href={props.data ? props.data.facebook : "/"} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.twitter : "/"} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.linkedin : "/"} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.youtube : "/"} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-youtube"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.instagram : "/"} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.whatsapp : "/"} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-whatsapp"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.email1 : "/"} target="_blank" rel="noopener noreferrer">
                                            <i className="fa fa-envelope"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="footer" style={{ marginTop: "10" }}>
                <div className="container text-center" >
                    <p>
                        Copyright &copy; {new Date().getFullYear()} Laathi ™ {" "}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Contact1;
