import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import './terms_&_condition.css';

const TermsAndCondition = ({ setHello }) => {
    

    useEffect(() => {
        setHello(true);
    }, [setHello]);

    return (

        <div className='main_div1'>
            <div className="col-md-12">
                <h2 className="heading-large" style={{ marginTop: '60px', marginBottom: '20px' }}>
                Terms and Conditions</h2>
                <h4>Last updated: June 17, 2024.</h4>
                <p className="paragraph-large" style={{ marginTop: '20px' }}>
                    Please read these terms and conditions carefully before using our Service.
                </p>
                <h2 className="heading-large2" style={{ marginBottom: '10px' }}>Acknowledgment</h2>
                <p className="paragraph-large">
                    These are the Terms and Conditions governing the use of this Service
                    and the agreement that operates between You and the Company. These Terms
                    and Conditions set out the rights and obligations of all users regarding
                    the use of the Service.<br/> <br/>

                    Your access to and use of the Service is conditioned on Your acceptance of
                    and compliance with these Terms and Conditions. These Terms and Conditions
                    apply to all visitors, users and others who access or use the Service.
                    <br/> <br/>

                    By accessing or using the Service You agree to be bound by these Terms
                    and Conditions. If You disagree with any part of these Terms and Conditions
                    then You may not access the Service.<br />

                    You represent that you are over the age of 18. The Company does not permit
                    those under 18 to use the Service.<br/> <br/>

                    Your access to and use of the Service is also conditioned on Your acceptance
                    of and compliance with the
                    <a href="/pages/privacy_policy.jsx"> Privacy Policy </a>
                    of the Company. Our Privacy Policy describes Our policies and procedures
                    on the collection, use and disclosure of Your personal information when
                    You use the Application or the Website and tells You about Your privacy
                    rights and how the law protects You. Please read our
                    <a href="/pages/privacy_policy.jsx"> Privacy Policy </a> carefully before
                    using our Service.<br/> <br/>
                </p>

                <h2 className="heading-large2" style={{ marginBottom: '10px' }}>
                User Accounts</h2>
                <p className="paragraph-large">
                    When You create an account with Us, You must provide Us information
                    that is accurate, complete, and current at all times. Failure to do so
                    constitutes a breach of the Terms, which may result in immediate termination
                    of Your account on Our Service.<br/> <br/>

                    You are responsible for safeguarding the password that You use to access
                    the Service and for any activities or actions under Your password, whether
                    Your password is with Our Service or a Third-Party Social Media Service.
                    <br/> <br/>

                    You agree not to disclose Your password to any third party. You must notify
                    Us immediately upon becoming aware of any breach of security or unauthorized
                    use of Your account.<br/> <br/>

                    You may not use as a username the name of another person or entity or that
                    is not lawfully available for use, a name or trademark that is subject to
                    any rights of another person or entity other than You without appropriate
                    authorization, or a name that is otherwise offensive, vulgar or obscene.
                    <br/> <br/>
                </p>
                <h2 className="heading-large2" style={{ marginBottom: '10px' }}>
                Your Feedback to Us</h2>
                <p className="paragraph-large">
                    You assign all rights, title and interest in any Feedback You provide
                    the Company. If for any reason such assignment is ineffective, You agree
                    to grant the Company a non-exclusive, perpetual, irrevocable, royalty free,
                    worldwide right and license to use, reproduce, disclose, sub-license,
                    distribute, modify and exploit such Feedback without restriction.
                </p>

                <h2 className="heading-large2" style={{ marginBottom: '10px' }}>
                Changes to These Terms and Conditions</h2>
                <p className="paragraph-large">
                    We reserve the right, at Our sole discretion, to modify or replace
                    these Terms at any time. If a revision is material We will make reasonable
                    efforts to provide at least 30 days' notice prior to any new terms
                    taking effect. What constitutes a material change will be determined
                    at Our sole discretion.<br/> <br/>

                    By continuing to access or use Our Service after those revisions become
                    effective, You agree to be bound by the revised terms. If You do not agree
                    to the new terms, in whole or in part, please stop using the website and
                    the Service.

                </p>

                <h2 className="heading-large2" style={{ marginBottom: '10px' }}>Contact Us</h2>
                <p className="paragraph-large" style={{ marginBottom: '20px' }}>
                    If you have any questions about these Terms and Conditions,
                    You can contact us: <br />
                </p>
                <p className="paragraph-large" style={{ marginBottom: '40px' }}>

                    <a href="/pages/contact.jsx" className="underline-on-hover">
                        Contact Us
                    </a>

                    <br />
                    <a href="mailto:help@laathi.com" className="underline-on-hover">
                        help@laathi.com
                    </a>
                </p>

            </div>
        </div>

    );
};

export default TermsAndCondition;
